import React, { useMemo, useState } from "react";
import { SearchBar } from "../../../client-page/SearchBar";
import { debounce } from "lodash";

const DEBOUNCE_WAIT = 500;

const ClientPageProjectQueryInput = ({
    values,
    onChange,
    onKeyPress,
}) => {
    const [searchQuery, setSearchQuery] = useState(values as string);

    const debouncedOnChange = useMemo(() => 
        debounce(
            (value: string) => onChange(value),
            DEBOUNCE_WAIT,
        ), 
        [onChange],
    );

    const onSearch = (e: React.FormEvent) => {
        e.preventDefault();

        const value = (e.target as HTMLInputElement).value;
        setSearchQuery(value);
        debouncedOnChange(value);
    }

    const clearSearch = () => {
        debouncedOnChange.cancel();
        onChange("");
        setSearchQuery("");
    };

    return (
        <SearchBar
            query={searchQuery}
            onChange={onSearch}
            onClear={clearSearch}
            onKeyDown={onKeyPress}
            anchor="right"
            outlined
        />
    );
}

export default ClientPageProjectQueryInput;
